import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import ApiService from "@/core/services/api.service";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "user-shops",
  data() {
    return {
      requiredRule: [v => !!v || "Field is required"],
      isSaving: false,
      userId: null,
      shops: [],
      userShops: [],
      shopsToAdd: [],
      userName: "",
      valid: false,
      search: "",
      shopsSearchInput: null,
      addShopDisable: false,
      validTo: null,
      shopsIsLoading: false,
      shopsTableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Shop", value: "name", sortable: false },
        { text: "Delete", value: "delete", sortable: false }
      ]
    };
  },
  async created() {
    this.userId = this.$route.params.userId;
    this.userName = this.$route.params.userName;
    this.$log.debug("userName: ", this.userName);
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Shops for user: " + this.userName }]);
    this.searchShops();
    this.retrieveUserShops();
  },

  methods: {
    remove(item) {
      const index = this.shopsToAdd.indexOf(item.id);
      if (index >= 0) this.shopsToAdd.splice(index, 1);
    },
    searchShops() {
      // Items have already been loaded
      if (this.shops.length > 0) return;

      // Items have already been requested
      if (this.shopsIsLoading) return;

      this.shopsIsLoading = true;
      ApiService.query("api/shops/dropdown-list")
        .then(res => {
          this.shops = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.shopsIsLoading = false));
    },

    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to remove " + item.name + "access for this user ?"
        )
      ) {
        this.deleteShopForUser(item);
      }
    },
    deleteShopForUser(item) {
      this.loading = true;
      if (item.id != null) {
        ApiService.delete(`api/users/${this.userId}/shops/${item.id}`)
          .then(response => {
            this.$log.debug("Response: ", response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.retrieveUserShops(), (this.loading = false)));
      }
    },
    retrieveUserShops() {
      if (this.userId != null) {
        ApiService.get(`api/users/${this.userId}/shops`)
          .then(response => {
            this.$log.debug("Shops: ", response.data);
            this.userShops = response.data;
            this.userShops.sort(function(a, b) {
              return a.id - b.id;
            });
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      }
    },
    previousState() {
      this.$router.go(-1);
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveShopTags(this.tags, this.shopId);
      }
    },
    addShopForUser() {
      this.$log.debug("shopsToAdd: " + this.shopsToAdd);
      this.addShopDisable = true;

      ApiService.post(`api/users/${this.userId}/add-shops`, this.shopsToAdd)
        .then(response => {
          this.$log.debug("userShops created: " + response);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => {
          this.retrieveUserShops();
          this.addShopDisable = false;
          this.loading = false;
          this.isSaving = false;
          this.shopsToAdd = [];
        });
    }
  },
  components: {
    ConfirmDialog
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
